import type { PersonType, StatusType } from '@/definitions/shared/types'
import { rgbToHex, styleVars } from '@/compositions/styleVariables'
import type { TaskCardType } from '@/definitions/tasks/types'
import type { KanbanColumnType } from '@/definitions/shared/kanban/types'
import type { TemplateType } from '@/components/modals/shared/SelectTemplateModal.vue'

const person: PersonType = {
  uid: 'person-1',
  firstName: 'A',
  lastName: 'A',
  fullName: 'Arlene McCoy',
  avatar: 'https://randomuser.me/api/portraits/women/11.jpg',
  avatarColor: 'gray',
}

export const tasksMock: TaskCardType[] = [
  {
    id: '434214',
    title: 'Set up a video call with the VIP client and his team',
    assignee: person,
    priority: '100',
    column: 'open',
    index: 0,
    attachments: 7,
    lastUpdated: '8 Jul 10:44 am',
  },
  {
    id: '612345',
    title: '[BYOC] Link more numbers modal doesn\'t show all available numbers',
    assignee: person,
    priority: '200',
    column: 'open',
    index: 1,
    lastUpdated: '4 Jun 1:34 am',
    attachments: 7,
    checklist: {
      total: 12,
      done: 4,
    },
    deadline: {
      type: '',
      date: '6 May 22',
    },
  },
  {
    id: '623144',
    title: 'Dynamic registration of ui services in container',
    assignee: person,
    priority: '200',
    column: 'open',
    lastUpdated: '2 Jul 09:12 pm',
    index: 2,
  },
  {
    id: '514245',
    title: 'Set up a video call with the VIP client and his team',
    assignee: person,
    priority: '200',
    column: 'open',
    index: 3,
    lastUpdated: '22 May 4:54 am',
    checklist: {
      total: 15,
      done: 0,
    },
    deadline: {
      type: 'danger',
      date: '12 Apr 22',
    },
  },
  {
    id: '463521',
    title: 'Set up a video call with the VIP client and his team',
    assignee: {
      ...person,
      avatar: '',
      fullName: 'Wade Warren',
      firstName: 'W',
    },
    priority: '300',
    column: 'attention',
    lastUpdated: '10 Apr 1:44 am',
    index: 4,
    attachments: 7,
    deadline: {
      type: 'danger',
      date: '12 Apr 22',
    },
  },
  {
    id: '567116',
    title: '[Front-end] [Chat AI] Enable AI only for users with Monthly plan active, showing promo to others',
    assignee: person,
    priority: '300',
    column: 'attention',
    lastUpdated: '1 May 2:34 pm',
    index: 5,
    deadline: {
      type: '',
      date: '6 May 22',
    },
  },
  {
    id: '751453',
    title: '[Carrera] - Wizard screens HTML',
    assignee: person,
    priority: '400',
    column: 'inProgress',
    attachments: 3,
    lastUpdated: '24 Apr 11:21 am',
    index: 6,
    deadline: {
      type: '',
      date: '6 May 22',
    },
  },
  {
    id: '352112',
    title: '[Carrera launch] - Update all email templates in sendwithus',
    assignee: person,
    priority: '200',
    column: 'inProgress',
    lastUpdated: '25 May 6:14 am',
    attachments: 1,
    index: 7,
    checklist: {
      total: 12,
      done: 4,
    },
    deadline: {
      type: 'warning',
      date: 'In 15 min',
    },
  },
  {
    id: '124311',
    title: '[Carrera S] - Implement the My account + Switch workspace + New profile updated dropdown with...',
    assignee: {
      ...person,
      fullName: 'Jane Cooper',
      avatar: '',
    },
    priority: '100',
    lastUpdated: '9 Jul 11:04 pm',
    column: 'inProgress',
    index: 8,
    deadline: {
      type: '',
      date: '6 May 22',
    },
  },
  {
    id: '213114',
    title: '[Chats] - Bold font for unread messages is not distinctive enough',
    assignee: person,
    priority: '100',
    column: 'inProgress',
    lastUpdated: '10 Apr 8:54 am',
    index: 9,
    deadline: {
      type: '',
      date: '6 May 22',
    },
  },
  {
    id: '341351',
    title: '[Front] Fix lazy rendering in modals',
    assignee: person,
    priority: '400',
    lastUpdated: '3 Jun 11:01 am',
    column: 'inProgress',
    index: 10,
    attachments: 2,
  },
  {
    id: '531114',
    title: '[Notes modal window truncated] Chat details/ when the user opens the contact\'s notes',
    assignee: {
      ...person,
      fullName: 'a',
      avatar: '',
    },
    priority: '200',
    column: 'inProgress',
    lastUpdated: '10 Apr 3:44 am',
    index: 11,
    deadline: {
      type: 'danger',
      date: '12 Apr 22',
    },
  },
]

export const taskStatuses: StatusType[] = [
  {
    name: 'Open',
    color: 'orange',
    outline: true,
  },
  {
    name: 'Need attention',
    color: 'red',
    outline: true,
  },
  {
    name: 'In progress',
    color: 'blue',
    outline: true,
  },
  {
    name: 'Solved',
    color: 'green',
    outline: true,
  },
]

export const tasksColumnsMock: KanbanColumnType[] = [
  {
    id: 'column-1',
    title: 'Open',
    name: 'open',
    color: rgbToHex(styleVars.orange500),
    items: [],
    collapsed: false,
    index: 0,
    status: taskStatuses.find((e: StatusType) => e.name === 'Open')!,
  },
  {
    id: 'column-2',
    title: 'Need attention',
    name: 'attention',
    color: rgbToHex(styleVars.red500),
    items: [],
    collapsed: false,
    index: 1,
    status: taskStatuses.find((e: StatusType) => e.name === 'Need attention')!,
  },
  {
    id: 'column-3',
    title: 'In progress',
    name: 'inProgress',
    color: rgbToHex(styleVars.blue500),
    items: [],
    collapsed: false,
    status: taskStatuses.find((e: StatusType) => e.name === 'In progress')!,
    index: 2,
  },
]

export const tasksTemplates: TemplateType[] = [
  {
    icon: 'tmi-file',
    title: 'Blank',
    text: 'Create a new board from scratch.',
  },
  {
    icon: 'tmi-campaign',
    title: 'Marketing',
    text: 'Visualize campaign progress and milestones.',
  },
  {
    icon: 'tmi-headset',
    title: 'Support',
    text: 'Monitor customer queries and resolution timelines.',
  },
  {
    icon: 'tmi-sale',
    title: 'Sales',
    text: 'Manage leads and opportunities pipeline.',
  },
  {
    icon: 'code',
    title: 'Development',
    text: 'Organize development sprints and feature tracking.',
  },
  {
    icon: 'settings',
    title: 'Management',
    text: 'Oversee project flow and team assignments.',
  },
  {
    icon: 'brush',
    title: 'Design',
    text: 'Layout design phases and creative processes.',
  },
  {
    icon: 'people',
    title: 'Recruitment',
    text: 'Organize recruiting and hiring tasks.',
  },
]
