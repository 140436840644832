
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TasksEmpty from '@/components/pages/tasks/index/TasksEmpty.vue'
import { useModes } from '@/compositions/modes'
import useTasks from '@/compositions/tasks/useTasks'

export default defineComponent({
  components: {
    PageContent,
    TasksEmpty,
    TmButton,
    DetailsHero,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const breadcrumbsLink = ref<BreadcrumbsLink[]>([
      {
        label: 'Tasks',
        name: 'base.tasks.index',
      },
      {
        label: 'Boards',
      },
    ])
    const { openTemplateModal } = useTasks()
    const { isEmptyMode, isEmptyHalfMode } = useModes()

    return {
      breadcrumbsLink,
      openTemplateModal,
      isEmptyMode,
      isEmptyHalfMode,
    }
  },
})
