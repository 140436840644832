import { useModals } from '@/compositions/modals'
import { colorPaletteOptionsOutline } from '@/definitions/_general/_data/colorPaletteOptions'
import { taskStatuses, tasksTemplates } from '@/definitions/tasks/data'

type UseTasks = () => {
  openEditBoardStage: () => void;
  openDeleteBoardStage: () => void;
  openDeleteTask: () => void;
  openTemplatePreviewModal: () => void;
  openTemplateModal: () => void;
  openDeleteTasks: () => void;
}

const useTasks: UseTasks = () => {
  const { openModal } = useModals()

  const openEditBoardStage = () => {
    openModal('addStatus', {
      modalTitle: 'Edit board stage',
      buttonText: 'Save',
      statusName: 'stage',
      paletteLabel: 'Stage color',
      paletteItems: colorPaletteOptionsOutline,
    })
  }

  const openDeleteBoardStage = () => openModal('confirmation', {
    title: 'Delete board stage',
    text: [
      'By confirming this action, the ',
      {
        text: 'In progress',
        style: 'semi-bold',
      },
      ' stage and ',
      {
        text: 'all tasks in this stage',
        style: 'semi-bold',
      },
      ' will be deleted. This action cannot be undone.\n\nAre you sure you want to delete this stage?',
    ],
    btnText: 'Delete stage',
    btnColor: 'error',
  })

  const openDeleteTasks = () => openModal('confirmation', {
    title: 'Delete tasks',
    text: [
      'All selected tasks will be deleted permanently. Are you sure you would like to delete the selected tasks?',
    ],
    btnText: 'Delete',
    btnColor: 'error',
  })

  const openDeleteTask = () => openModal('confirmation', {
    title: 'Delete task',
    text: [
      'The selected task will be deleted permanently. Are you sure you would like to delete the selected task?',
    ],
    btnText: 'Delete',
    btnColor: 'error',
  })

  const openTemplateModal = () => {
    openModal('selectTemplate', {
      templates: tasksTemplates,
      title: 'Select a new board template',
      clickFunc: () => openTemplatePreviewModal(),
    })
  }
  const openTemplatePreviewModal = () => {
    openModal('templatePreview', {
      title: 'Marketing template',
      backFunc: () => openTemplateModal(),
      nextFunc: () => openModal('tasksForm'),
      description: 'Visualize campaign progress and milestones.',
      image: require('@/assets/images/tasks/template-image.svg'),
      statuses: taskStatuses,
      statusesLabel: 'Task stages',
    })
  }

  return {
    openTemplatePreviewModal,
    openTemplateModal,
    openEditBoardStage,
    openDeleteTasks,
    openDeleteTask,
    openDeleteBoardStage,
  }
}

export default useTasks
